<template>
    <div>
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>微信设置</el-breadcrumb-item>
            <el-breadcrumb-item>预约前提示</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form style="max-width: 600px;margin:0 auto" :model="form" ref="form" label-width="100px" class="form">
            <el-form-item align="center">
                <h3>预约前提示</h3>
            </el-form-item>
            <el-form-item label="标题">
                <el-input v-model="form.title" placeholder="标题"></el-input>
            </el-form-item>
            <el-form-item label="内容">
                <el-input type="textarea" v-model="form.content" :rows="15" placeholder="内容"></el-input>
            </el-form-item>
            <el-form-item label="是否启动">
                <el-switch v-model="form.enabled" active-color="#13ce66" inactive-color="#cccccc"/>
            </el-form-item>
            <el-form-item align="center">
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    title: '',
                    content: '',
                    enabled: true,
                }
            }
        },
        methods: {
            async submitForm() {
                const resp = await this.$http.post(`/mingde/wechat/alert`, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                }
            },
            async getData() {
                const resp = await this.$http.get(`/mingde/wechat/alert`)
                this.form = resp.data.data
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

